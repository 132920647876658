/* Rentals.css */
.image-zoom {
    overflow: hidden;
    position: relative;
  }
  
  .image-zoom img {
    transition: transform 0.2s; 
  }
  
  .image-zoom:hover img {
    transform: scale(1.1); 
  }
  